import * as React from "react"
import { Link } from "gatsby"

import Showcase from "../components/features/showcase"
import SimpleNav from "../components/navs/simpleNav"
import Products from "../components/features/products"
import FourColFooter from "../components/footers/fourColFooter"
import LTextDivider from "../components/dividers/lTextDivider"
import SocialOnlyFooter from "../components/footers/socialOnlyFooter"

import { Blue } from "../data/color"
import websites from "../data/websites"

const Classroom = () => (
  <div>
  
  <SimpleNav color={Blue.color} textColor={Blue.textBold} focusColor={Blue.focusColor} />
  <Showcase bgBold={Blue.bgBold} hoverBold={Blue.hoverBold} name={'E-Learning Site'} desc={'Developed React and Tailwind CSS using Gatsby'} visit="/page-2/" picture="https://i.imgur.com/oZLZc5b.png" />

  <Products name={"More"} projects={websites} textBold={Blue.textBold} textMd={Blue.textMd} />
  <SocialOnlyFooter />

</div>

)

export default Classroom